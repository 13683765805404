import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useCheckout } from '../context';

function EnrollPlacements({ placements }) {
    const [state, dispatch] = useCheckout();
    const place = state.program.name.includes('Coding') ? '12' : '6';
    const [placement, setPlacement] = useState(place);
    const handleInputChange = (e) => {
        setPlacement(e.target.value);
        dispatch({ type: 'order', payload: { ...state.order, placement: parseInt(e.target.value, 10) } });
    };

    return (
      <>
        <h6 className="font-weight-bold mt-2">Esquemas de pago disponibles: </h6>

        <div className="form-group col-md-12">
          {placements.map(plan => (
                  (
                    <label className={placement === `${plan.value}` ? 'form-control-option form-control-option--active' : 'form-control-option'} htmlFor={`months-${plan.value}`} key={plan.value}>
                      <input type="radio" id={`months-${plan.count}`} name="months" value={plan.value} onClick={handleInputChange} checked={placement === `${plan.value}`} />
                      {`${plan.value} meses`}
                    </label>
                  
                  )))
        }
        </div>
      </>
     
    );
}

EnrollPlacements.propTypes = {
 placements: propTypes.arrayOf(propTypes.shape()).isRequired,
};

export default EnrollPlacements;
