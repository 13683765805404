
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {  
    injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement 
} from 'react-stripe-elements';
import MeetDevf from './meetDevf';
import { useCheckout } from '../context';
import EnrollPlacements from './enrollPlacements';
import { PLACEMENTS_MASTER } from '../const';

function NewFormStripe({ cardSubmit, stripe }) {
  const [state, dispatch] = useCheckout();
  const [name, setName] = useState('');
  const [checked, setChecked] = useState(false);
  const [infoComplete, setInfocomplete] = useState(false);
  const [errorCard, setErrorCard] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [errorCvc, setErrorCvc] = useState(null);
  const placements = PLACEMENTS_MASTER.filter((placement) => {
    if (state.approach.product_line !== 'MASTER_2D') {
      if (placement.value === '6') return placement;
    } else {
      return placement;
    }
  });
  
  const submit = async (ev) => {
    ev.preventDefault();
    setInfocomplete(false);
    const { token } = await stripe.createToken({ name, email: state.alumni.email });
    return cardSubmit(name, token);
  };

  const handleChecked = () => {
    if (checked) {
      dispatch({ type: 'order', payload: { ...state.order, accept_terms_date: null } });
    } else {
      dispatch({ type: 'order', payload: { ...state.order, accept_terms_date: new Date() } });
    }
    setChecked(!checked);
  };

  useEffect(() => {
    if (!errorCard && !errorCvc && !errorDate && name.length > 3) {
      setInfocomplete(true);
    } else {
      setInfocomplete(false);
    }
  }, [errorCard, errorCvc, errorDate, name]);

  const change = (e) => {
    if (e.elementType === 'cardNumber') {
      if (e.complete) setErrorCard(null);
      else if (e.error) setErrorCard(e.error.message);
      else setErrorCard('Completa la información');
    }
    if (e.elementType === 'cardExpiry') {
      if (e.complete) setErrorDate(null);
      else if (e.error) setErrorDate(e.error.message);
      else setErrorDate('Completa la información');
    }
    if (e.elementType === 'cardCvc') {
      if (e.complete) setErrorCvc(null);
      else if (e.error) setErrorCvc(e.error.message);
      else setErrorCvc('Completa la información');
    }
  };

  return (
    <>
      <div className="form-info d-flex my-4">
        <h6 className="font-weight-bold">Información de contacto</h6>
      </div>
      <div>
        <form className="formulario" onSubmit={submit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <CardNumberElement onChange={change} placeholder="Número de tarjeta" className="formulario-input" style={{ base: { fontSize: '15px' } }} />
              <span className="formulario-input-error">{errorCard}</span>
            </div>
            <div className="form-group col-md-3">
              <CardExpiryElement onChange={change} className="formulario-input" style={{ base: { fontSize: '15px' } }} />
              <span className="formulario-input-error">{errorDate}</span>
            </div>
            <div className="form-group col-md-2">
              <CardCvcElement onChange={change} className="formulario-input" style={{ base: { fontSize: '15px' } }} />
              <span className="formulario-input-error">{errorCvc}</span>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <input type="text" className="form-control" name="cardHolder" placeholder="Nombre que aparece en la tarjeta" onChange={e => setName(e.target.value)} />
            </div>
          </div>
          <EnrollPlacements placements={placements} />
          <MeetDevf />

          <label htmlFor="terms">
            <input type="checkbox" id="terms" name="terms" onChange={handleChecked} required />            
            {' '}
            Aceptar 
            {' '}
            <a target="_blank" href="https://devf.la/terminos" rel="noopener noreferrer">términos y condiciones</a>
          </label>

          <div className="form-acceso text-center">
            <button disabled={!infoComplete} type="submit" id="purchase-card" className={`but ${!infoComplete ? 'but-grey' : 'but-purple'} w-100`}>Pagar</button>
          </div>
        </form>
      </div>
    </>
  );
}

NewFormStripe.propTypes = {
  cardSubmit: PropTypes.func.isRequired,
  stripe: PropTypes.shape().isRequired
};

export default injectStripe(NewFormStripe);
