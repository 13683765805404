import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useCheckout } from '../context';
import dateFormat from '../utils/dateFormat';
import currencyFormat from '../utils/currencyFormat';
import devfLogo from '../img/svg/logo-devf.svg';

function ResumeMaster({ amount, fullPrice = false }) {
  const [state] = useCheckout();
  const { approach, order } = state;
  const date = dateFormat(approach.batch_detail.start_date);
  // const price = parseInt(approach.price_given, 10);
  // const monthly = price / 10;
  return (
    <Fragment>
      <div className="row">
        <div className="col-2">
          <img src={devfLogo} alt="cinta" />
        </div>
        <div className="col-7">
          <h6 className="font-weight-bold">
            {state.program ? state.program.name : 'Master en coding'}
            -
            {date}
          </h6>
          { !fullPrice && order && order.placement && order.placement !== 0 && (
          <p className="mb-1">
1ra. mensualidad de: 
            {' '}
            $
            {Math.floor(amount / order.placement)}
            .00
            {' '}
          </p>
)}
        </div>
        <div className="col-3 text-right">
          <strong>
            {currencyFormat(parseInt(amount, 10)) 
              + approach.currency }
          </strong>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-7 offset-2">
          <h6 className="font-weight-bold">
            Descuento por beca: 
          </h6>
        </div>
        <div className="col-3 text-right">
          -
          <strong>
            {currencyFormat(
              parseInt(approach.price_offered, 10) - parseInt(approach.price_given, 10)
            ) + approach.currency }
          </strong>
        </div>
      </div>
      <div className="row">
        <div className="col-7 offset-2">
          <h6 className="font-weight-bold">
            Total a pagar: 
          </h6>
        </div>
        <div className="col-3 text-right">
          <strong>
            {currencyFormat(
              parseInt(approach.price_given, 10)
            ) + approach.currency }
          </strong>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-7 offset-2">
          <h6 className="font-weight-bold">
            Mesualidad por : 
          </h6>
        </div>
        <div className="col-3 text-right">
          <strong>
            {currencyFormat(monthly)}
          </strong>
        </div>
        <div className="col-7 offset-2">
          <h6 className="font-weight-bold">
            Corte: 
          </h6>
        </div>
        <div className="col-3 text-right">
          <strong>
            {`${date.split(' ')[0]} de cada mes x 10 meses`}
          </strong>
        </div>
        <div className="col-12 text-right">
          <sup>
            El cobro mensual emepzará el día
            {' '}
            {date}
          </sup>
        </div>
      </div> */}
      <hr />
      {fullPrice && (
      <div className="row">
        <div className="col-7 offset-2">
          <h6 className="font-weight-bold">
            Pago: 
          </h6>
        </div>
        <div className="col-3 text-right">
          <strong>
            {currencyFormat(amount)}
          </strong>
        </div>
      </div>
)}
      <div className="resumen-total text-right">
      Total: 
        <span className="precio-total">{currencyFormat(amount) + approach.currency}</span>
      </div>      
    </Fragment>
  );
}

ResumeMaster.propTypes = {
  amount: PropTypes.number.isRequired,
  fullPrice: PropTypes.bool.isRequired,
};

export default ResumeMaster;
