import React, { Fragment, useEffect, useState } from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/es/images';
import PropTypes from 'prop-types';
import useForm from '../hooks/useForm';
import { ERROR_MESSAGES } from '../const';
import MeetDevf from './meetDevf';

function PaymentForm({ cardSubmit, cashSubmit }) {
  const [isFilled, setFill] = useState(false);
  const {
    meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps
  } = usePaymentInputs({ errorMessages: ERROR_MESSAGES });

  const { inputs, handleInputChange, handleSubmit } = useForm(cardSubmit);

  useEffect(() => {
    const {
      cardNumber, expiryDate, cvc, cardHolder
    } = inputs;
    if (cardNumber && expiryDate && cvc && cardHolder) {
      setFill(true);
    } else {
      setFill(false);
    }
  }, [inputs]);

  return (
    <Fragment>
      <div className="form-info">
        <h6 className="font-weight-bold">Información de contacto</h6>
      </div>
      <div data-private>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group d-flex justify-content-center align-items-center col-md-1">
              <svg {...getCardImageProps({ images })} />
            </div>
            <div className="form-group col-md-6">
              <input {...getCardNumberProps({ onChange: handleInputChange })} placeholder="Número de tarjeta" className="form-control" />
            </div>
            <div className="form-group col-md-3">
              <input {...getExpiryDateProps({ onChange: handleInputChange })} placeholder="MM/AA" className="form-control" />
            </div>
            <div className="form-group col-md-2">
              <input {...getCVCProps({ onChange: handleInputChange })} className="form-control" type="password" />
            </div>

            {meta.isTouched && meta.error && (
              <small className="d-block text-danger">
                Error:
                {meta.error}
              </small>
            )
            }
          </div>


          <div className="form-row">
            <div className="form-group col-md-12">
              <input type="text" className="form-control" onChange={handleInputChange} name="cardHolder" value={inputs.cardHolder} placeholder="Nombre que aparece en la tarjeta" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <select className="form-control" defaultValue="0" onChange={handleInputChange} name="months">
                <option value="0"> Pago al contado </option>
                <option value="3"> 3 meses sin intereses </option>
                <option value="6"> 6 meses sin intereses </option>
                <option value="9"> 9 meses sin intereses </option>
                <option value="12"> 12 meses sin intereses </option>
              </select>
            </div>
          </div>
          <MeetDevf />
          <div className="form-acceso text-center">
            <button type="submit" id="purchase-card" className={isFilled ? 'but but-purple w-100' : 'but but-grey w-100'} disabled={!isFilled}>PAGAR AHORA</button>

            <h6 className="font-weight-bold my-5">SI PREFIERES PAGAR EN EFECTIVO</h6>

            <button type="button" id="purchase-cash" className="but but-yellow mb-4" onClick={() => cashSubmit('store')}>PAGO EN TIENDAS EN EFECTIVO</button>

            <button type="button" id="purchase-transfer" className="but but-cyan mb-4" onClick={() => cashSubmit('bank_account')}>TRANSFERENCIA BANCARIA</button>

          </div>
        </form>
      </div>
    </Fragment>
  );
}

PaymentForm.propTypes = {
  cardSubmit: PropTypes.func.isRequired,
  cashSubmit: PropTypes.func.isRequired
};

export default PaymentForm;
