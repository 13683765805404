/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function GTM({ tags }) {
  useEffect(() => {
    window.dataLayer.push(tags);
  }, [tags]);

  
  return <></>;
}


GTM.propTypes = {
  tags: PropTypes.shape().isRequired
};

export default GTM;
