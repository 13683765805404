import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../common/Loader';
import Header from '../components/header';
import Head from '../components/head';
import Footer from '../components/footer';
import ResumeOrders from '../components/ResumeOrders';
import Modal from '../common/Modal';
import Error from '../common/Error';
import PayPalButton from '../components/paypalButton';
import PaymentForm from '../components/paymentForm';
import GTM from '../common/GTM';
import { theBrainAPI, djangoAPI } from '../utils/http';
import { useCheckout } from '../context';
import createCardToken from '../utils/creditCardToken';
import currencyConverter from '../utils/currencyConverter';
import orderService from '../services/orderService';
import {
  OPENPAY_MERCHANT_ID,
  OPENPAY_BANK_PDF,
  OPENPAY_STORE_PDF
} from '../const';
import InfoAlumniOrder from '../components/infoAlumniOrder';
import PaymentFormStripe from '../components/paymentFormStripe';
import stripeService from '../services/scholarshipStripeService';

function Order({ match, history, location }) {
  const [state, dispatch] = useCheckout();
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState('');
  const [pdp] = useState(new URLSearchParams(location.search).get('pdp'));
  

  useEffect(() => {
    const { idOrder } = match.params;
    const fetchOrders = async () => {
      const { data } = await theBrainAPI().get(`/order/${idOrder}`);
      const infoBatch = await djangoAPI().get(
        `/batch/${data.approach.sede_detail.city}`
      );
      const sede = await theBrainAPI().get(
        `/sede/${data.approach.sede_detail.city}`
      );
      // const [cinta] = infoBatch.data.sede.cintas.filter(
      //   c => c.slug === data.approach.program
      // );
      if (data.order.is_payment) history.push('/paid');
      if (data.approach.is_payed) history.push('/paid');
      dispatch({ type: 'scholarship', payload: data.approach });
      dispatch({ type: 'paymentOrder', payload: data.order });
      dispatch({ type: 'alumni', payload: data.alumni });
      dispatch({ type: 'batch', payload: infoBatch.data });
      dispatch({ type: 'cinta', payload: { name: data.approach.program } });
      dispatch({ type: 'order', payload: { price: data.order.amount } });
      dispatch({ type: 'sedeDetail', payload: sede.data });
      setLoading(false);
    };

    fetchOrders();
  }, [match, dispatch, history]);

  const load = (isLoad, message) => {
    setIsOpen(isLoad);
    setContent(message || <Loader />);
  };

  const cardSubmit = async (fields) => {
    try {
      const { months } = fields;
      setIsOpen(true);
      setContent(<Loader />);
      const token = await createCardToken(fields, window.OpenPay);
      const payment = { type: 'card', token: token.id, months };
      const [service, data] = await orderService(state, payment);
      dispatch({ type: 'approach', payload: data });
      if (service) history.push('/confirm');
    } catch (e) {
      setContent(<Error message={e.message} close={setIsOpen} />);
    }
  };

  const cashSubmit = async (type) => {
    try {
      setIsOpen(true);
      setContent(<Loader />);
      dispatch({ type: 'payment', payload: { type } });
      const [service, approach] = await orderService(state, { type });
      const { charge } = approach;
      const url = type === 'store'
          ? `${OPENPAY_STORE_PDF}${OPENPAY_MERCHANT_ID}/${charge.payment_method.reference}`
          : `${OPENPAY_BANK_PDF}${OPENPAY_MERCHANT_ID}/${charge.id}`;
      if (service) {
        dispatch({ type: 'payment', payload: { url } });
        history.push('/confirm');
      }
    } catch (e) {
      setContent(<Error message={e.message} close={setIsOpen} />);
    }
  };

  const paypalSucces = async (data, actions) => {
    try {
      setIsOpen(true);
      setContent(<Loader />);
      const capture = await actions.order.capture();
      const payload = {
        type: 'paypal',
        id: data.orderID,
        email_address: capture.payer.email_address
      };
      const [service] = await orderService(state, payload);
      if (service) history.push('/confirm');
    } catch (e) {
      setContent(<Error message={e.message} close={setIsOpen} />);
    }
  };

  const cardSubmitStripe = async (_, month) => { 
    try {
      setIsOpen(true);
      setContent(<Loader />);
      const confirmBody = {
        intentId: month.paymentIntent,
        months: month.month,
        selectedPlan: month.month,
        type: 'card', 
      };
      const confirm = await stripeService(state, confirmBody);
      if (confirm.status === 200) load(false, null); 
      else {
        dispatch({ type: 'stripe', payload: { error: true } });
        setIsOpen(true);
        return setContent(<Error message="Error creando el cargo" close={setIsOpen} />);
      }
      return history.push('/paid');
    } catch (e) {
      dispatch({ type: 'stripe', payload: { error: true } });
      if (e.raw) return setContent(<Error message={e.raw.message} close={setIsOpen} />);
      if (e.response) setContent(<Error message={e.response.data.raw.message} close={setIsOpen} />);
      return setContent(<Error message="Ha ocurrido un error desconocido. Comunícate con estudiantes@devf.mx" close={setIsOpen} />);
    }
  };

  const paypalError = (e) => {
    setIsOpen(true);
    setContent(<Error message={e.message} close={setIsOpen} />);
  };


  const selectedPdP = () => {
    if (state.scholarship.currency !== 'MXN') {
      return (
        state.scholarship.currency !== 'COP' ? (<PaymentFormStripe plans={false} cardSubmit={cardSubmitStripe} load={load} />) : (
          <>
            <PayPalButton
              price={currencyConverter[state.scholarship.currency](
              state.order.price
            )}
              onSucces={paypalSucces}
              onError={paypalError}
            />
            <spam>**Paypal cobra comisiones ajenas a DEV.F en algunos países.</spam>
          </>
        )
      );
    }
    return pdp === 'epirts' 
    ? <PaymentFormStripe cardSubmit={cardSubmitStripe} load={load} plans={false} />
    : <PaymentForm cardSubmit={cardSubmit} cashSubmit={cashSubmit} />;
  };

  return (
    <div>
      <GTM tags={{ pageView: 'order' }} />
      <Head title="Orden" />
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container py-4">
          <div className="row">
            <div className="col-md-6 order-12 order-md-1">
              <InfoAlumniOrder
                alumni={state.alumni}
                cinta={state.cinta && state.cinta.name ? `${state.scholarship.product_line} ${state.cinta.name}` : ''}
                orders={state.scholarship.orders}
              />
              {selectedPdP()}
            </div>
            <div className="resumen col-md-6 bg-light py-5 px-4 order-1 order-md-12 mb-4">
              <ResumeOrders />
            </div>
          </div>
          <GTM
            tags={{
              pageView: 'order',
              cinta: state.cinta.name,
              price: state.order.price,
              currency: state.order.currency
            }}
          />
        </div>
      )}
      <Footer />

      <Modal isOpen={isOpen}>{content}</Modal>
    </div>
  );
}

Order.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default Order;
