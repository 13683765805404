/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCheckout } from '../context';
import Header from '../components/header';
import Head from '../components/head';
import Footer from '../components/footer';
import InfoAlumniScholarship from '../components/infoAlumniScholarship';
import Loader from '../common/Loader';
import GTM from '../common/GTM';
import Modal from '../common/Modal';
import { theBrainAPI } from '../utils/http';
import ResumeMaster from '../components/resumeMaster';
import Error from '../common/Error';
import NewFormStripe from '../components/NewFormStripe';
import newEnrollStripeService from '../services/newEnrollStripeService';
import Axios from 'axios';

function PayProgram({ match, history }) {
  const [state, dispatch] = useCheckout();
  const [isOpen, setIsOpen] = useState(false);
  const [enrollPrice, setEnrollPrice] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  useEffect(() => {
    if (state.approach && state.order) {
      const price = state.approach.price_given;
      setEnrollPrice(Math.floor(price));
    }
  }, [state.approach, state.order]);

  useEffect(() => {
    async function enrollmentData() {
      try {
        const { data } = await theBrainAPI().get(`/approaches/${match.params.idApproach}`);          
        if (data.length > 0) {
          const {
            _id, first_name, last_name, email, phone, approaches 
          } = data[0];
          dispatch({ type: 'approach', payload: approaches });
          dispatch({
            type: 'alumni',
            payload: {
              first_name, last_name, email, phone, _id 
            } 
          });
          const { data: program } = await theBrainAPI().get(`/checkout/progam/${approaches.program}`);  
          dispatch({ type: 'program', payload: program });
          dispatch({ type: 'order', payload: { placement: program.slug === 'data_ds' ? 6 : 12, force: false } });
        } else {
          setError('Parece que no encontramos tu inscripción. Ponte en contacto con el equipo de DEV.F para resolverlo');
        }
      } catch (err) {
        setError('Tuvimos un problema procesando tu inscripción. Escríbenos a estudiantes@devf.mx para resolverlo.');
      } finally {
        setLoading(false);
      }
    }
    enrollmentData();
  }, [dispatch, match.params]);

  const cardSubmitStripe = async (cardName, token) => {
    setIsOpen(true);
    setModalContent(<Loader />);
    try {
      const confirmBody = {
        customer: {
          name: `${state.alumni.first_name} ${state.alumni.last_name}`,
          email: state.alumni.email,
        },
        source: {
          source: token.id,
        },
        type: 'card', 
        currency: state.approach.currency,
        placement: state.order.placement,
        force: state.order.force,
      };
      await newEnrollStripeService(state, confirmBody);
      history.push('/paid');
    } catch (e) {
      if (e.raw) {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email} message:${e.raw.message}` });
        setModalContent(<Error message={e.raw.message} close={setIsOpen} />);
      } else if (e.response) {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email} message:${e.response.data.raw.message}` });
        setModalContent(<Error message={e.response.data.raw.message} close={setIsOpen} />);
      } else {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email}  Error nuestro` });
        setModalContent(<Error message="Ha ocurrido un error desconocido. Comunícate con estudiantes@devf.mx" close={setIsOpen} />);
      }
    }
  };

  const render = () => {
    if (error) return <Error message={error} close={() => {}} />;
    if (isLoading) return <Loader />;
    return (
      <div className="container py-4">
        <div className="row">
          <div className="col-md-6 order-12 order-md-1">
            <InfoAlumniScholarship
              alumni={state.alumni}
              cinta={state.program.name}
            />
            <NewFormStripe cardSubmit={(name, token) => cardSubmitStripe(name, token)} />
          </div>
          <div className="resumen col-md-6 bg-light py-5 px-4 order-1 order-md-12 mb-4">
            <ResumeMaster amount={enrollPrice} />
          </div>
        </div>
        <GTM
          tags={{
              pageView: 'master',
              cinta: 'code',
              price: state.approach.price_given,
              currency: state.approach.currency,
              orderID: state.approach.soicos,
              leadType: 'CPA'
            }}
        />
      </div>
);
  };

  return (
    <div>
      <Head title="Inscríbete" />
      <Header />
      {render()}
      <Footer isLoading={false} />
      <Modal isOpen={isOpen}>{modalContent}</Modal>
    </div>
    );  
}

PayProgram.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired
};
  

export default PayProgram;
