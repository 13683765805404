import React from 'react';
import PropTypes from 'prop-types';
import warning from '../img/warning.png';

function Error({ message, close }) {
  return (
    <div className="row justify-content-center">
      <div className="col-md-7 d-flex justify-content-center">
        <img src={warning} className="img-error" alt="error" />
      </div>
      <div className="col-md-7 text-center">
        <h3>{message}</h3>
        <button type="button" className="but but-purple" onClick={() => close(false)}> Cerrar </button>
      </div>
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default Error;
