/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { Fragment, useState, useEffect } from 'react';
import {  
injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement 
  } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import { useCheckout } from '../context';
import { theBrainAPI } from '../utils/http';
import MeetDevf from './meetDevf';
import { MASTER_ENROLL_PRICE } from '../const';
import Modal from '../common/Modal';
import Loader from '../common/Loader';

function ErrorPaymentIntentModal({ isOpen }) {
  return (
    <Modal isOpen={isOpen} header="No es posible hacer tu pago">
      <h4>Nuestro sistema no acepta este tipo de tarjeta. Por favor intenta con otra o contacta a tu asesor de ventas.</h4>
      <br />
      <button type="button" id="purchase-card" className="but but-purple w-100" onClick={() => window.location.reload()}>Intentar con otra tarjeta</button>
    </Modal>
);
}

function PaymentFormStripe({
  cardSubmit, stripe, load, advances = true, plans = true, fullPrice = false
  }) {
  const [name, setName] = useState('');
  const [checked, setChecked] = useState(false);
  const [errorCard, setErrorCard] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [errorCvc, setErrorCvc] = useState(null);
  const [openModalError, setOpenModalError] = useState(false);
  const [complete, setComplete] = useState(
    { cardNumber: false, cardExpiry: false, cardCvc: false }
    );
  const [month, setmonth] = useState(0);
  const [monthSelected, setmonthSelected] = useState('0');

  const [monthValid, setMonthValid] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [paymentMethodValid, setPaymentMethodValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useCheckout();
  const [isPackage] = useState(!(!state.package));

  const handleChecked = () => {
    if (checked) {
      dispatch({ type: 'order', payload: { ...state.order, accept_terms_date: null } });
    } else {
      dispatch({ type: 'order', payload: { ...state.order, accept_terms_date: new Date() } });
    }
    setChecked(!checked);
  };

  const change = (e) => {
    if (e.elementType === 'cardNumber') {
      if (e.error) setErrorCard(e.error.message);
      if (e.complete) {
        setErrorCard(null);
      }
    }
    if (e.elementType === 'cardExpiry') {
      if (e.error) setErrorDate(e.error.message);
      if (e.complete) {
        setErrorDate(null);
      }
    }
    if (e.elementType === 'cardCvc') {
      if (e.error) setErrorCvc(e.error.message);
      if (e.complete) {
        setErrorCvc(null);
      }
    }
    setComplete({ ...complete, [e.elementType]: e.complete });
    if (errorCard === null && errorDate === null && errorCvc === null) {
      setDisabled(false);
    }
  };

  const handleMonth = async (e) => {
    setmonthSelected(e.target.value);
    if (e.target.value !== '0') {
      setPaymentMethodValid(true);

      return setmonth({
        ...month,
        // eslint-disable-next-line eqeqeq
        month: monthValid.filter(plan => plan.count == e.target.value)[0],
      });
    } 
    return setmonth(null);
  };

  const validCard = async () => {
    setLoading(true);
    const { paymentMethod, error } = await stripe.createPaymentMethod('card', {
      billing_details: { 
        name, 
        email: state.alumni ? state.alumni.email : state.packageFull.alumni.email 
      }
    });

    if (error) {
      setOpenModalError(true);
      return setmonth(null);
    }
    const data = isPackage
        // eslint-disable-next-line no-underscore-dangle
      ? await theBrainAPI().post(`/payment/valid/package/${state.package._id}`, {
        email: state.alumni.email,
        reserve: typeof (state.package.reserve) === 'string',
        numberReserve: state.package.reserve,
        token: paymentMethod.id,
        currency: state.sedeDetail ? state.sedeDetail.currency : state.packageFull.alumni.currency,
        plan: month ? month.month : '',
        isFull: typeof state.packageFull === 'object',
        pay: typeof state.packageFull === 'object' ? state.packageFull.pay : 0
      })
      : await theBrainAPI().post('/payment/valid/stripe/', { 
        email: state.alumni.email,
        token: paymentMethod.id,
        plan: month ? month.month : '',
        currency: state.approach
          ? state.approach.currency : state.scholarship 
          ? state.scholarship.currency : state.sedeDetail.currency,
        price: !advances ? fullPrice ? state.approach.price_given : MASTER_ENROLL_PRICE[state.approach.currency] 
          : state.order.coupon && state.order.discount && !state.order.reserve 
          ? state.order.price - state.order.discount 
          : state.order.reserve 
            ? state.order.priceReserve : state.order.price 
          
      });
      setPaymentMethodValid(true);
      dispatch({ type: 'stripe', payload: { intent: data.data.id, error: false } });
      setmonth({ ...month, paymentIntent: data.data.id });
      setLoading(false);
      return setMonthValid(
        data.data.payment_method_options.card.installments
          .available_plans.filter(plan => plan.count <= 12)
      );
  };

  const submit = async (ev) => {
    ev.preventDefault();
    load(true);
    if (!advances) {
      const { token } = await stripe.createToken({ name, email: state.alumni.email });
      return cardSubmit(token, month);
    } 
    return cardSubmit(null, month);
  };

  useEffect(() => {
    if (errorCard === null && errorDate === null && errorCvc === null && name !== ''
        // && (!state.stripe || state.stripe.error) 
        && complete.cardNumber && complete.cardCvc && complete.cardExpiry) {
      validCard();
    }
  }, [errorCard, errorDate, errorCvc, name, complete]);

  useEffect(() => {
    if (state.packageFull && state.packageFull.pay) {
      validCard();
    }
  }, [state.packageFull]);

  const handleSetName = (e) => {
    // const reguexName = /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;
    if (name === '' && e.target.value.length > 8) setName(e.target.value);
  };

  return (
    <Fragment>
      <div className="form-info d-flex my-4">
        <h6 className="font-weight-bold">Información de pago</h6>
      </div>
      <ErrorPaymentIntentModal isOpen={openModalError} />
      <div>
        <form className="formulario" onSubmit={submit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <CardNumberElement onChange={change} placeholder="Número de tarjeta" className="formulario-input" style={{ base: { fontSize: '15px' } }} />
              <span className="formulario-input-error">{errorCard}</span>
            </div>
            <div className="form-group col-md-3">
              <CardExpiryElement onChange={change} className="formulario-input" style={{ base: { fontSize: '15px' } }} />
              <span className="formulario-input-error">{errorDate}</span>
            </div>
            <div className="form-group col-md-2">
              <CardCvcElement onChange={change} className="formulario-input" style={{ base: { fontSize: '15px' } }} />
              <span className="formulario-input-error">{errorCvc}</span>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <input disabled={disabled} type="text" className="form-control" name="cardHolder" placeholder="Nombre que aparece en la tarjeta" onChange={handleSetName} />
            </div>
          </div>

          <h6 className="font-weight-bold mt-2">Esquemas de pago</h6>
          <p className="mb-4">ingresá tu tarjeta para conocer tus esquemas de pago.</p>
          { (isPackage && state.package.venues[0].currency !== 'MXN' && monthValid.length > 0) || !plans ? <></> : (
            <div className="form-row">
              { loading ? (
                <div className="w-100 d-flex justify-content-center">
                  {' '}
                  <Loader />
                  {' '}
                </div>
                )
              : (
                <div className="form-group col-md-12">
                  <label className={monthSelected === '0' ? 'form-control-option form-control-option--active' : 'form-control-option'} htmlFor="months-0">
                    <input type="radio" id="months-0" name="months" value="0" defaultChecked onClick={handleMonth} />
                  Pago de contado
                    {' '}
                  </label>
                  {name && monthValid.length < 1 && (<span style={{ color: 'red' }}> Tu tarjeta de crédito no acepta meses sin intereses</span>) }
                  {monthValid.map(plan => (
                  (plan.count % 6 === 0) && (
                  <label className={monthSelected === `${plan.count}` ? 'form-control-option form-control-option--active' : 'form-control-option'} htmlFor={`months-${plan.count}`} key={plan.count}>
                    <input type="radio" id={`months-${plan.count}`} name="months" value={plan.count} onClick={handleMonth} />
                    {`${plan.count} meses sin intereses`} 
                    {' | '} 
                    {`Mensualidad de: $
                     ${plan.count === 6 ? Math.floor(state.approach.price_given / plan.count) : Math.ceil(state.approach.price_given / plan.count)}.00`}
                  </label>
                  )
                    ))}
                  {!name && monthValid.length < 1 && (
                  <>
                    <label className="form-control-option form-control-option-disabled" htmlFor="months-0" key={2}>
                      <input type="radio" id="months-0" name="months" value={0} onClick={handleMonth} disabled />
                      {'6 meses sin intereses'} 
                    </label>
                    <label className="form-control-option form-control-option-disabled" htmlFor="months-0" key={2}>
                      <input type="radio" id="months-0" name="months" value={0} onClick={handleMonth} disabled />
                      {'12 meses sin intereses'} 
                    </label>
                  </>
)}
                </div>
                )
              }
            </div>
            )}
          <MeetDevf />

          <label htmlFor="terms">
            <input type="checkbox" id="terms" name="terms" onChange={handleChecked} required />            
            {' '}
            Aceptar 
            {' '}
            <a target="_blank" href="https://devf.la/terminos" rel="noopener noreferrer">términos y condiciones</a>
          </label>

          <div className="form-acceso text-center">
            <button disabled={!paymentMethodValid} type="submit" id="purchase-card" className={`but ${!paymentMethodValid ? 'but-grey' : 'but-purple'} w-100`}>{ loading ? 'Cargando...' : 'Pagar'}</button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

PaymentFormStripe.propTypes = {
  cardSubmit: PropTypes.func.isRequired,
  stripe: PropTypes.shape().isRequired,
  load: PropTypes.func.isRequired,
  advances: PropTypes.bool.isRequired,
  fullPrice: PropTypes.bool.isRequired,
};

export default injectStripe(PaymentFormStripe);
