import { setSession } from './utils/session';
import LogRocket from 'logrocket';

export default function reducer(state, action) {
  let newState = {};
  switch (action.type) {
    case 'cinta': 
    case 'batch': 
    case 'sede': 
    case 'order': 
    case 'alumni': 
    case 'payment':
    case 'billing':
    case 'approach':
    case 'scholarship': 
    case 'paymentOrder':
    case 'sedeDetail':
    case 'package':
    case 'stripe':
    case 'master':
    case 'program':
    case 'packageFull':
      if (action.type === 'alumni') {
        if (action.payload && action.payload.email) {
          // eslint-disable-next-line no-underscore-dangle
          LogRocket.identify(action.payload._id, {
            email: action.payload.email,
          });
        }
      }
      newState = { ...state, [action.type]: { ...action.payload } }; 
      setSession(newState);
      return newState;
    default: return state;
  }
}
