import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useCheckout } from '../context';

function Steps({ final }) {
  const [state] = useCheckout();
  return (
    <ul className="steps d-flex">
      <li className="d-flex flex-fill align-items-center active"><span className="step-text step-finish"><Link to={`/?sede=${state.sedeDetail.city}&cinta=${state.cinta.slug}`}>Resumen de compra</Link></span></li>
      <li className="d-flex flex-fill align-items-center active"><span className={(final ? 'step-text step-finish' : 'step-text step-middle-01')}><Link to="/alumni">Registro de alumno</Link></span></li>
      <li className={(final ? 'd-flex flex-fill align-items-center active' : 'd-flex flex-fill align-items-center')}><span className={(final ? 'step-text step-end-02' : 'step-text step-end')}>Información de pago</span></li>
    </ul>
  );
}

Steps.propTypes = {
  final: PropTypes.bool
};

Steps.defaultProps = {
  final: false
}; 


export default Steps;
