import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import Home from './views/home';
import Inscripcion from './views/inscripcion';
import Scholarship from './views/scholarship';
import Master from './views/master';
import Payment from './views/payment';
import Order from './views/order';
import Confirm from './views/confirm';
import Paid from './views/paid';
import PaymentPackage from './views/paymentPackage';
import Package from './views/package';
import PackageFull from './views/packageFull';
import BillForm from './views/billForm';
// import Enroll from './views/enroll';
import Checkout from './views/checkout';
import PayProgram from './views/PayProgram';
import PayComplete from './views/paycomplete';

function Routes() {
  return (
    <div>
      <Elements>
        <Switch>
          
          <Route exact path="/complete/:idApproach" component={PayComplete} />
          <Route exact path="/enrollment/:idApproach" component={PayProgram} />
          <Route exact path="/confirm" component={Confirm} />
          <Route exact path="/paid" component={Paid} />
          <Route exact path="/inscripcion" component={Inscripcion} />

        </Switch>
      </Elements>
    </div>
  );
}

export default Routes;
