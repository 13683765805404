import React from 'react';
import { Link } from 'react-router-dom';

function Continue() {
  return (
    <div className="inscribete row py-2 text-right justify-content-end">
      <div className="col-5">
        <Link to="/alumni" className="but but-purple" id="apply-now"> Inscríbete ahora </Link>

      </div>
      <div className="col-12 py-4">
        <p>
          <small>
            Al comprar aceptas los 
            <a href="."> términos y condiciones </a> 
            de DEV.F
            <br />
            También te recomendamos leer nuestro 
            <a href="."> aviso de privacidad </a>
          </small>
        </p>
      </div>

    </div>
  );
}
export default Continue;
