/* eslint-disable quote-props */
const DJANGO_BASE_URL = process.env.REACT_APP_DJANGO_URL;
const THE_BRAIN_BASE_URL = process.env.REACT_APP_THE_BRAIN_URL; 
const ERROR_MESSAGES = {
  emptyCardNumber: 'El número de la tarjeta es inválido',
  invalidCardNumber: 'El número de la tarjeta es inválido',
  emptyExpiryDate: 'La fecha de expiración es inválida',
  monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
  yearOutOfRange: 'El año de expiración no puede estar en el pasado',
  dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
  invalidExpiryDate: 'La fecha de expiración es inválida',
  emptyCVC: 'El código de seguridad es inválido',
  invalidCVC: 'El código de seguridad es inválido'
};
const OPENPAY_MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID;
const OPENPAY_PUBLIC_ID = process.env.REACT_APP_PUBLIC_API_KEY;
const OPENPAY_BANK_PDF = process.env.REACT_APP_OPEN_PAY_PDF;
const OPENPAY_STORE_PDF = process.env.REACT_APP_OPEN_PAY_STORE_PDF;
const DEBUG = process.env.REACT_APP_DEBUG === 'true';
const PAYPAY_KEY = process.env.REACT_APP_PAYPAL;
const PRICE_REMOTE = {
  mxn: {
    price: 12000,
    currency: 'MXN',
    reserve: 1000
  },
  usd: {
    price: 630,
    currency: 'USD',
    reserve: 50
  }
};
const MASTER_ENROLL_PRICE = {
  USD: 49,
  MXN: 999
};

const ENROLL_PRICE = {
 MASTER_2D: {
   MXN: {
    '11': 1999,
    '5': 3999
   },
   USD: {
    '11': 99,
    '5': 199
   }
 
 },
 MASTER_2D6M: {
  MXN: {
    '11': 1999,
    '5': 3999
   },
   USD: {
    '11': 99,
    '5': 199
   }
 },
 MASTER: {
  MXN: {
    '5': 1500
   },
   USD: {
    '5': 75
   }
 },
 PROFESSIONAL: {
  MXN: {
    '5': 1500
   },
   USD: {
    '5': 75
   }
 },
};

const PLACEMENTS_MASTER = [
    { 'value': '6', 'text': '6 Mensualidades' },
    { 'value': '12', 'text': '12 Mensualidades' },
  ];

// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_NUMBER = /[0-9]*/;
const REGUEX_RFC = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/;
const PRICE_FINANCIEMIENTO = {
  MXN: 20499,
  USD: 1201,
};

export {
  DJANGO_BASE_URL,
  THE_BRAIN_BASE_URL,
  OPENPAY_MERCHANT_ID,
  OPENPAY_PUBLIC_ID,
  ERROR_MESSAGES,
  OPENPAY_BANK_PDF,
  OPENPAY_STORE_PDF,
  DEBUG,
  PAYPAY_KEY,
  REGEX_EMAIL,
  REGEX_NUMBER,
  REGUEX_RFC,
  PRICE_REMOTE,
  MASTER_ENROLL_PRICE,
  ENROLL_PRICE,
  PLACEMENTS_MASTER,
  PRICE_FINANCIEMIENTO
};
