import React, { useState } from 'react';
import PropTypes from 'prop-types';
import currencyFormat from '../utils/currencyFormat';
import { useCheckout } from '../context';


function ResumeDiscount({ coupon, discount, total }) {
  const readOnlyCoupon = useState(coupon)[0];
  const [state] = useCheckout();
  return (
    <>
      <div className="d-flex justify-content-between">
        <small>
          Código de descuento:
          {' '}
          <strong>{readOnlyCoupon}</strong>
          {' '}
        </small>

        <strong>
          -
          {currencyFormat(state.order.reserve ? parseInt(state.order.priceReserve, 10) : parseInt(state.order.discount, 10)) + state.order.currency }
        </strong>
      </div>
      <hr />
      <div className="d-flex justify-content-between my-4">
        <strong>Total:</strong>
        <span className="precio-total">
          { currencyFormat(total - discount) + state.order.currency }
        </span>
      </div>
      <hr />
    </>
  );
}

ResumeDiscount.propTypes = {
  coupon: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default ResumeDiscount;
