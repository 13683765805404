import React, { 
  useState, Suspense, lazy, useEffect 
} from 'react';
import Modal from '../common/Modal';
import dateFormat from '../utils/dateFormat';
import currencyFormat from '../utils/currencyFormat';
import { useCheckout } from '../context';

const ListSedes = lazy(() => import('./listSedes'));
const ListCintas = lazy(() => import('./listCintas'));

function Cinta() {
  const [state] = useCheckout();
  const {
 cinta, sedeDetail, batch, order 
} = state;
  const price = parseInt(sedeDetail.price, 10);
  const date = dateFormat(batch.start_date);
  const [modalSedeOpen, setModalSedeOpen] = useState(false);
  const [modalCintaOpen, setModalCintaOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(price);
  

  useEffect(() => {
    setTotalPrice(parseInt(order.price, 10));
  }, [order.price]);

  return (
    <tr>
      <td className="d-flex align-items-center">
        <div className="row">
          <div className="col-md-3 text-center text-md-left">
            <figure><img src={cinta.image_url} alt="cinta" /></figure>
          </div>
          <div className="col-md-9 text-center text-md-left">
            <h6 className="font-weight-bold">
              {cinta.name}
              , Empieza el
              {' '}
              {date}
            </h6>
            <p className="mb-1">{sedeDetail.title}</p>
            <p>
              <small><a href="#sede " onClick={() => setModalSedeOpen(true)}>CAMBIAR SEDE</a></small>
              <small className="ml-3"><a href="#sede " onClick={() => setModalCintaOpen(true)}>CAMBIAR CINTA</a></small>
            </p>
            <p><small><a href={`https://devf.la/${sedeDetail.city}/${cinta.slug}`} target="_blank" rel="noopener noreferrer">VER DETALLES DEL CURSO</a></small></p>
            <small>
              **Lo más importante es nuestra comunidad, 
              en esta época del COVID-19, tu cinta será de manera remota en nuestra plataforma
              <a href="https://campus.devf.la"> campus.devf.la</a>
              {' '}
              <br />
              {' '}
              Para más información conoce nuestro comunicado en 
              {' '}
              <a href="https://devf.la/desdecasa">devf.la/desdecasa</a>
              {' '}
            </small>


          </div>
        </div>
      </td>
      <td className="t-total text-center">
        {currencyFormat(totalPrice) + order.currency}
      </td>

      <Modal isOpen={modalSedeOpen} classModal="modalLarge" size="lg">
        <Suspense fallback="Cargando...">
          <ListSedes sede={sedeDetail} setModal={setModalSedeOpen} cinta={cinta.slug} />
        </Suspense>
      </Modal>

      <Modal isOpen={modalCintaOpen} classModal="modalLarge" size="lg">
        <Suspense fallback="Cargando...">
          <ListCintas cinta={cinta} setModal={setModalCintaOpen} sede={sedeDetail.cinta} />
        </Suspense>
      </Modal>

    </tr>

  );
}
export default Cinta;
