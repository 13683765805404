import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Head from '../components/head';
import Header from '../components/header';
import Footer from '../components/footer';
import Overview from '../components/overview';
import Price from '../components/price';
import Discount from '../components/discount';
import Continue from '../components/continue';
import Loader from '../common/Loader';
import GTM from '../common/GTM';
import { djangoAPI, theBrainAPI } from '../utils/http';
import { useCheckout } from '../context';
import { PRICE_REMOTE } from '../const';

const buildOrder = (data) => {
  const order = {
    price: parseInt(data.price, 10), 
    currency: data.currency,
    priceReserve: data.reserve || 0
  };
  // if (data.sede && data.sede.early_bird) {
  // order = { 
  //   ...order, coupon: 'EARLYBIRD', 
  //   discount: Math.ceil((data.price * data.sede.discount_early) / 100) 
  // };
  // }
  return order;
};

function Home({ location, history }) {
  const [state, dispatch] = useCheckout();
  const [isLoading, setLoading] = useState(true);
  const [codePromo, setCodePromo] = useState('');

  useEffect(() => {
    if (!location.search) history.push('/?sede=cdmx&cinta=cintablanca');
    const params = new URLSearchParams(location.search);
    if (params.get('sede') !== 'remote') setCodePromo(params.get('code'));
    const fetchCintas = async () => {
      const { data } = await djangoAPI().get(`/sedes/${params.get('sede')}/`);
      const [cinta] = data.cintas.filter(
        c => c.slug === params.get('cinta')
      );
      if (!cinta) history.push(`/?sede=${data.sede.slug}&cinta=cintablanca`);
      dispatch({ type: 'cinta', payload: cinta });
    };
    const fetchBatch = async () => {
      const { data } = params.get('day') ? await theBrainAPI().get('/batch/-99') : await theBrainAPI().get('/batch?current=true&productLine=COURSE');      
      dispatch({ type: 'batch', payload: data, });
    };
    const fetchSede = async () => {
      const { data } = await theBrainAPI().get(`/sede/${params.get('sede')}`);
      const order = params.get('c') ? buildOrder({ price: PRICE_REMOTE[params.get('c')].price, currency: PRICE_REMOTE[params.get('c')].currency, reserve: PRICE_REMOTE[params.get('c')].reserve }) : buildOrder(data);
      dispatch({ type: 'order', payload: order });
      dispatch({ type: 'sedeDetail', payload: data });
    };
    fetchSede();
    fetchBatch();
    fetchCintas();
  }, [location.search, dispatch, history]);

  useEffect(() => {
    if (state.batch && state.cinta && state.sedeDetail) {      
      setLoading(false);
    }
  }, [state]);

  return (
    <div>
      <Head title="DEV.F | Checkout" />
      <Header />
      <div className="container">
        <div className="row py-4">
          <div className="col">
            <h1>Tu inscripción</h1>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <Overview />
            <Price />
            <Discount codePromo={codePromo} />
            <Continue />
            <GTM
              tags={{
                pageView: 'home',
                sede: state.sedeDetail.title,
                cinta: state.cinta.name,
                price: state.order.price
              }}
            />
          </Fragment>
        )}
      </div>
      <Footer />
    </div>
  );
}

Home.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default Home;
