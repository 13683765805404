import React, { useState } from 'react';
import { useCheckout } from '../context';

function MeetDevf() {
    const [meeDevf, setMeeDevf] = useState('n');
    const [state, dispatch] = useCheckout();

    const handleInputChange = (e) => {
        setMeeDevf(e.target.value);
        dispatch({ type: 'order', payload: { ...state.order, meet_devf: e.target.value } });
    };

    return (
      <select className="form-control mb-3 mt-3" defaultValue="n" name="meet_devf" required value={meeDevf} onChange={handleInputChange}>
        <option value="">¿Cómo te enteraste de DEV.F?</option>
        <option value="FB">Facebook</option>
        <option value="INSTAGRAM">Instagram</option>
        <option value="TW">Twitter</option>
        <option value="TIKTOK">Tiktok</option>
        <option value="LINKEDIN">Linkedin</option>
        <option value="GOOGLE">Búsqueda en Google</option>
        <option value="YT">YouTube</option>
        <option value="FRIEND">Amigo o Familiar</option>
        <option value="EVENT">Evento</option>
        <option value="EX">Soy ex-alumno</option>
      </select>
    );
}

export default MeetDevf;
