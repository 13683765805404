import React from 'react';
import { Link } from 'react-router-dom';
import happy from '../img/happy.png';

const CardConfirmation = () => (
  <div className="row justify-content-center">
    <div className="col-md-8 d-flex flex-column align-items-center text-center Confirm">
      <img src={happy} className="img-confirm mb-4" alt="happy" />
      <h3 className="mb-4">Bienvenid@ a la Familia DEV.F</h3>
      <p className="mb-4">
        Puedes comenzar a prepararte para tus clases desde este
        momento.
      </p>
    </div>
    <div className="col-md-8 text-center">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to="/billing" className="but but-green">
        Puedes facturar aquí 
      </Link>
    </div>
  </div>
);

export default CardConfirmation;
