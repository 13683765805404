import dotenv from 'dotenv';
import React from 'react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import setupLogRocketReact from 'logrocket-react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import Routes from './Routes';
import { CheckOutProvider } from './context';
import { DEBUG } from './const';
import './index.scss';
import 'bootstrap/scss/bootstrap.scss';

if (!DEBUG) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY });
  LogRocket.init('nwsvrl/checkoutdevf');
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

dotenv.config();

ReactDOM.render(
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
    <CheckOutProvider>
      <Router>
        <Routes />
      </Router>
    </CheckOutProvider>
  </StripeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
