import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button';
import Head from '../components/head';
import Header from '../components/header';
import Footer from '../components/footer';
import GTM from '../common/GTM';
import wink from '../img/wink.png';
import 'react-floating-whatsapp-button/dist/index.css';


function Paid() {
  return (
    <>
      <GTM tags={{ pageView: 'confirm' }} />
      <Head title="Confirm" />
      <Header />
      <main id="main-inscripcion">
        <section id="confirmation" className="my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="row justify-content-center">
                  <div className="col-md-8 d-flex flex-column align-items-center text-center Confirm">
                    <img src={wink} className="img-confirm mb-4" alt="happy" />
                    <h3 className="mb-4"><strong>¡Tu pago se está procesado!</strong></h3>
                    <p className="mb-4">
                      Pronto recibirás tú correo de bienvenida o te avisaremos por whatsapp.
                      Si tienes dudas tambien nos puedes escribir 
                      {' '}
                      <a href="https://api.whatsapp.com/send?phone=+5215561734619&text=Quiero%20saber%20el%20estatus%20de%20mi%20pago,%20mi%20correo%20es:" target="_blank" rel="noopener noreferrer">aquí</a>
.
                      ¡Sigue disfrutando de tus clases!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FloatingWhatsApp 
          phone="+525561734619"
          popupMessage="Hola, ¿Tienes alguna duda?"
          headerTitle="Ventas DEVF"
          autoOpenTimeout={60000}
          zIndex={999}
        />
      </main>
      <Footer />
    </>
  );
}

export default Paid;
