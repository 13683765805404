import React, { Fragment } from 'react';
import { useCheckout } from '../context';
import dateFormat from '../utils/dateFormat';
import currencyFormat from '../utils/currencyFormat';


function ResumeScholarship({ isAdvance }) {
  const state = useCheckout()[0];
  const { cinta, scholarship } = state;
  const date = dateFormat(scholarship.batch_detail.start_date);
  return (
    <Fragment>
      <div className="row">
        <div className="col-2">
          <figure><img src={cinta.image_url || 'https://img.icons8.com/pastel-glyph/2x/code.png'} alt="cinta" /></figure>
        </div>
        <div className="col-7">
          <h6 className="font-weight-bold">
            {cinta.name}
            ,
            {date}
          </h6>
          {/* <p className="mb-1">{scholarship.sede_detail.city}</p> */}
        </div>
        <div className="col-3 text-right">
          <strong>
            {currencyFormat(parseInt(scholarship.price_given, 10)) + scholarship.currency }
          </strong>
        </div>
      </div>
      <br />
      {!isAdvance && (
      <div className="row">
        <div className="col-7 offset-2">
          <h6 className="font-weight-bold">
            Descuento por beca: 
          </h6>
        </div>
        <div className="col-3 text-right">
          -
          <strong>
            {currencyFormat(
              parseInt(scholarship.price_offered, 10) - parseInt(scholarship.price_given, 10)
            ) + scholarship.currency }
          </strong>
        </div>
      </div>
      )}
      {state.order.reserve ? (
        <div className="row">
          <div className="col-7 offset-2">
            <h6 className="font-weight-bold">
              Pago por 
            </h6>
          </div>
          <div className="col-3 text-right">
            <strong>
              {currencyFormat(parseInt(state.order.priceReserve, 10)) 
                + scholarship.currency }
            </strong>
          </div>
          <div className="col-7 offset-2">
            <h6 className="font-weight-bold">
              Restan: 
            </h6>
          </div>
          <div className="col-3 text-right">
            <strong>
              {currencyFormat(parseInt(scholarship.price_given - state.order.priceReserve, 10)) 
                + scholarship.currency }
            </strong>
          </div>
        </div>
        ) : <> </>
      }
      <hr />
      <div className="resumen-total text-right">
        Total:
        <span className="precio-total">{currencyFormat(state.order.reserve ? parseInt(state.order.priceReserve, 10) : parseInt(scholarship.price_given, 10)) + scholarship.currency }</span>
      </div>      
    </Fragment>
  );
}

export default ResumeScholarship;
