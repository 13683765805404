import React from 'react';
import PropTypes from 'prop-types';


function InfoAlumniScholarship({ alumni, cinta }) {
  return (
    <div>
      <h4>
        Felicidades
        <strong>
          {` ${alumni.first_name} ${alumni.last_name}`}
        </strong>
      </h4>
      <p>
        Estás a un paso de inscribirte a 
        <strong>
          {` ${cinta} `}
        </strong> 
      </p>
    </div>
  );
}

InfoAlumniScholarship.propTypes = {
  alumni: PropTypes.shape().isRequired,
  cinta: PropTypes.string.isRequired,
};

export default InfoAlumniScholarship;
