/* eslint-disable no-underscore-dangle */
import { theBrainAPI } from '../utils/http';
import updateApproach from '../utils/updateApproach';

const enrollAlumni = (body, idApproach) => theBrainAPI().post(`/new/payment/enroll/${idApproach}`, body);

function stripeService(state, body) {
  const {
    alumni, approach, ipDetails, order
  } = state;

  return new Promise(async (resolve, reject) => {
    try {
      const enroll = await enrollAlumni(body, approach._id);
      await updateApproach({
        country_detail: ipDetails.country,
        city_detail: ipDetails.city,
        meet_devf: order ? order.meet_devf : undefined,
      },
        alumni._id, approach._id);
      resolve(enroll);
    } catch (e) {
      reject(e.response.data);
    }
  });
}  

export default stripeService;
