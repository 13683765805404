import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const HubspotContactForm = ({ onSubmit, portalId, formId }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId,
                    formId,
                    target: '#hubspotForm',
                    onFormSubmit: ($form) => {
                        onSubmit($form);
                    }   
                });
            }
        });
    }, [onSubmit]);

    return (
      <div>
        <div id="hubspotForm" />
      </div>
    );
};

HubspotContactForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
  };
export default HubspotContactForm;
