import React from 'react';
import { Modal as M, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

function Modal({ 
  isOpen, classModal, children, size, header 
}) {
  return (
    <M isOpen={isOpen} className={classModal} size={size} header={header}>
      {header && <ModalHeader>{header}</ModalHeader>}
      <ModalBody>
        {children}
      </ModalBody>

    </M>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  classModal: PropTypes.string,
  header: PropTypes.string,
  size: PropTypes.string
};

Modal.defaultProps = {
  classModal: '',
  header: null,
  size: 'md'
};

export default Modal;
