import React from 'react';
import PropTypes from 'prop-types';
import cool from '../img/cool.png';

function CashConfirmation({ url }) {
  return (
    <div className="row justify-content-center">

      <div className="col-md-8 d-flex flex-column align-items-center text-center Confirm">
        <img src={cool} className="img-confirm mb-4" alt="happy" />
        <h3 className="mb-4">Tu lugar ha sido reservado</h3>
        <p className="mb-4">
          Una vez realizado tu pago, tu lugar está asegurado y
          podrás acceder a la comunidad Dev.F.
        </p>
        <a href={url} target="_blank" rel="noopener noreferrer" className="but but-green"> Descargar Ficha de Pago </a>

      </div>
    </div>


  );
}

CashConfirmation.propTypes = {
  url: PropTypes.string.isRequired
};

export default CashConfirmation;
