import React from 'react';
import devfLogo from '../img/svg/logo-devf.svg';

function Header() {
  return (
    <header id="header" className="border-bottom mb-4 py-2">
      <div className="container">
        <nav className="navbar px-0">
          <a href=".">
            <img src={devfLogo} height="30px" className="d-inline-block align-middle" alt="" />
          </a>
        </nav>
      </div>
    </header>
  );
}
export default Header;
