/* eslint-disable no-underscore-dangle */
import ReactGA from 'react-ga';
import { theBrainAPI } from '../utils/http';
import { approachBuilder } from '../utils/builders';
import sendStripe from '../utils/sendStripe';
import createOrder from '../utils/createOrder';

ReactGA.initialize(process.env.REACT_APP_GA); // Identificador Google Analytics

const createAlumni = alumni => theBrainAPI().post('/alumni', alumni);
const updateAlumni = (alumni, alumniID) => theBrainAPI().patch(`/checkout/alumni/${alumniID}`, alumni);
const fetchBatch = () => theBrainAPI().get('/batch?current=true');
const createApproach = (approach, alumniId) => theBrainAPI().post(`/alumni/${alumniId}/approach`, approach);

function stripeService(state, payment) {
  const {
    cinta, batch, order, alumni, billing, sedeDetail, ipDetails
  } = state;

  return new Promise(async (resolve, reject) => {
    try {
      if (alumni.picture) delete alumni.picture;
      const editedAlumni = {
        ...alumni,
        phone: alumni.code + alumni.phone
      };
      delete editedAlumni.code;
      const student = await createAlumni(editedAlumni);
      const currentBatch = await fetchBatch();
      const bacthId = currentBatch.data._id;
      const sedeId = sedeDetail._id;
      
      const approach = approachBuilder({
        cinta: cinta.slug,
        price: order.coupon && order.discount ? order.price - order.discount : order.price,
        bacthId,
        sedeId,
        currency: order.currency,
        price_offered: sedeDetail.price,
        meet_devf: order.meet_devf,
      });
      if (billing) {
        await updateAlumni(billing, student.data._id);
        approach.is_invoice = true;
      }
      if (sedeId.city === 'remote') approach.is_remote = true;
      if (order.coupon) approach.discount_code = order.coupon;
      approach.country_detail = ipDetails.country;
      approach.city_detail = ipDetails.city;
      const createdApproach = await createApproach(approach, student.data._id);
      const buyApproach = await sendStripe(payment, createdApproach.data._id);

      if (buyApproach.status === 200) {
        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
          name: `${batch.name} ${cinta.name} - ${sedeDetail.title}`,
          id: payment.intentId,
          price: order.price,
          currency: order.currency,
          brand: 'DEV.F',
          quantity: 1,
          coupon: order.coupon || '' // Optional fields may be omitted or set to empty string.
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.plugin.execute('ec', 'clear');

        if (order.reserve) {
          const amount = (order.reserve && order.discount ? (order.price - order.discount) : order.price) 
            - parseInt(order.priceReserve, 10);
          await createOrder(student.data._id, createdApproach.data._id, { amount });
        }

        resolve(buyApproach);
      } else {
        reject(new Error('Error al hacer el cargo'));
      }
    } catch (e) {
      reject(e.response.data);
    }
  });
}  

export default stripeService;
