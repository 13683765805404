import React from 'react';
import PropTypes from 'prop-types';
import whiteLogo from '../img/svg/logo-devf-white.svg';

function Footer({ isLoading }) {
  return (
    <footer id="footer" style={{ marginTop: isLoading ? '20%' : '5%' }}>
      <div id="info-contact" className="pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 text-center text-md-left pb-4">
              <img src={whiteLogo} alt="devf logo" className="logo-footer img-fluid" />
            </div>
            <div className="col-lg-3 col-md-3 text-center text-md-left pb-4">
              <nav className="nav flex-column">
                <a href="https://devf.la/#expert">PROGRAMAS</a>
                <a href="https://devf.la/">ACERCA DE NOSOTROS</a>
              </nav>
            </div>
            <div className="col-lg-6 col-md-6 text-center text-md-right">
              <p><a href="." mailto="hola@devf.la" className="text-green">hola@devf.la</a></p>
            </div>
          </div>
          <div className="row copyright pb-3 pt-5 pt-sm-3">
            <div className="col-sm-12 col-md-5 text-center text-md-left mb-3">
              <a href=".">TÉRMINOS Y CONDICIONES</a>
              |
              <a href=".">AVISO DE PRIVACIDAD</a>
            </div>
            <div className="col-sm-12 col-md-7 text-center text-md-right">
              <span>HACKEANDO MÉXICO DESDE 2014. TODOS LOS DERECHOS RESERVADOS</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default Footer;
