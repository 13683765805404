/* eslint-disable no-underscore-dangle */
import ReactGA from 'react-ga';
import { theBrainAPI } from '../utils/http';
import { approachBuilder, paymentBuilder } from '../utils/builders';
import createOrder from '../utils/createOrder';
import sendPayment from '../utils/sendPayment';

ReactGA.initialize(process.env.REACT_APP_GA); // Identificador Google Analytics

const createAlumni = alumni => theBrainAPI().post('/alumni', alumni);
const updateAlumni = (alumni, alumniID) => theBrainAPI().patch(`/checkout/alumni/${alumniID}`, alumni);
const createApproach = (approach, alumniId) => theBrainAPI().post(`/alumni/${alumniId}/approach`, approach);


function checkService(state, payment) {
  const {
    cinta, batch, order, alumni, billing, sedeDetail, ipDetails
  } = state;

  return new Promise(async (resolve, reject) => {
    try {
      if (alumni.picture) delete alumni.picture;
      const editedAlumni = {
        ...alumni,
        phone: alumni.code + alumni.phone,
      };
      delete editedAlumni.code;
      const student = await createAlumni(editedAlumni);
      const bacthId = batch._id;
      const sedeId = sedeDetail._id;
      const approach = approachBuilder({
        cinta: cinta.slug,
        price: order.price,
        bacthId,
        sedeId,
        currency: order.currency,
        price_offered: sedeDetail.price,
        is_remote: sedeDetail.city === 'remote',
        meet_devf: order.meet_devf,
      });
      if (billing) {
        await updateAlumni(billing, student.data._id); 
        approach.is_invoice = true;
      }
      approach.city_detail = ipDetails.city;
      approach.country_detail = ipDetails.country;
      const createdApproach = await createApproach(approach, student.data._id);
      const newPayment = paymentBuilder({
        payment_method: payment.type,
        code: order.coupon,
        payment_month_without_interest: payment.months,
        card_token: payment.token,
        paymentID: payment.id,
        advance: order.reserve,
        sede_detail: sedeDetail._id,
      });
      if (newPayment.payment_method === 'paypal') Object.assign(newPayment, { payment_associated_email: payment.email_address });
      const buyApproach = await sendPayment(newPayment, createdApproach.data._id);
      if (buyApproach.status === 200) {
        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
          name: `${batch.name} ${cinta.name} - ${sedeDetail.title}`,
          id: payment.id,
          price: order.price,
          currency: order.currency,
          brand: 'DEV.F',
          quantity: 1,
          coupon: order.coupon || '' // Optional fields may be omitted or set to empty string.
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.plugin.execute('ec', 'clear');
        if (order.reserve) {
          const amount = (order.reserve && order.discount ? (order.price - order.discount) : order.price) 
            - parseInt(order.priceReserve, 10);
          await createOrder(student.data._id, createdApproach.data._id, { amount });
        }
        resolve([true, buyApproach.data]);
      } else {
        reject(new Error('Error al hacer el cargo'));
      }
    } catch (e) {
      reject(e.response.data);
    }
  });
}

export default checkService;
