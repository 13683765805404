import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import reducer from './reducers';
import { initSession } from './utils/session';


const initialState = initSession();
const CheckOutContext = createContext();

const CheckOutProvider = ({ children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <CheckOutContext.Provider value={contextValue}>
      {children}
    </CheckOutContext.Provider>
  );
};

CheckOutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useCheckout = () => {
  const contextValue = useContext(CheckOutContext);
  return contextValue;
};

export {
  CheckOutProvider,
  useCheckout
};
