import React, { useState, useEffect, useRef } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button';
import HubspotForm from '../components/HubspotForm';
import Header from '../components/header';
import Head from '../components/head';
import Footer from '../components/footer';
import { theBrainAPI } from '../utils/http';
import Mixpanel from '../utils/mixpanel';
import Loader from '../common/Loader';
import 'react-floating-whatsapp-button/dist/index.css';


import GTM from '../common/GTM';
import Modal from '../common/Modal';

function Inscripcion() {
    const [email, setEmail] = useState(null);
    const [methodPay, setMethodPay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [registro, setRegistro] = useState(false);
    const [safeMessage, setSafeMessage] = useState(false);
    const counter = useRef(0);
    const getDeal = mail => theBrainAPI().post('/checkout/getLinkByEmail', { email: mail });
   
    const redirectFromHubspot = async (mail) => {
      const deal = await getDeal(mail).catch(() => {
        if (!registro) {
          setRegistro(true);
          setEmail(null);
        }
      });
      if (deal && deal.data.properties.link_de_inscripcion) {
          Mixpanel.track('checkout-link-success', { email: mail });
          window.location = deal.data.properties.link_de_inscripcion;
      }   
    };

    const onSubmit = async (e) => {
        e.preventDefault();
       await redirectFromHubspot(email);
    };


    useEffect(() => {
      if (email && registro) {
        const interval = setInterval(async () => {
          counter.current += 1;
          await redirectFromHubspot(email, methodPay === 'msi');
          if (counter.current > 10 && !safeMessage) setSafeMessage(true);
        }, 5000);
       
        return () => clearInterval(interval);
      }
    }, [email, methodPay, redirectFromHubspot, registro, safeMessage]);

  return (
    <>
      <GTM tags={{ pageView: 'alumni' }} />
      <Head title="Inscripción" />  
      <div className="d-flex flex-column justify-content-between" style={{ height: '75vh' }}>
        <Header />
        <div className="container py-4">
          <div className={`row justify-content-center ${registro && 'd-none'}`}>
            <div className="col-md-6 order-12 order-md-1">
              <>
                <div className="form-info d-flex my-4">
                  <h6 className="font-weight-bold">Ingresa tu correo electrónico</h6>

                </div>
                <form className="formulario" onSubmit={onSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input type="email" className="form-control" placeholder="Correo electrónico" name="email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </div>
                  </div>

                  <div className="form-acceso text-center">
                    <button type="submit" className="but but-purple w-100" id="add-payment">Proceder al pago</button>
                  </div>

                </form>
              </>
            </div>
          </div>
          <div className={`row justify-content-center ${!registro && 'd-none'}`}>
            <div className="col-md-6 col-sm-12 col-lg-6">
              <h2>Por favor completa tus datos</h2>
              <HubspotForm
                portalId="7483854"
                formId="f2a85317-68bd-47fc-8593-de1443c2a36e"
                onSubmit={(form) => {
                  setIsOpen(true);
                  setEmail(form.email.value);
                  setMethodPay(form.forma_de_pago.value);
                  Mixpanel.track('checkout-link-registro', { email: form.forma_de_pago.value });
                }}
              />
    
            </div>
          </div>
          <FloatingWhatsApp 
            phone="+525561734619"
            popupMessage="Hola, ¿Tienes alguna duda?"
            headerTitle="Ventas DEVF"
            autoOpenTimeout={60000}
            zIndex={999}
          />
        </div>
        <Footer />
      </div>
      <Modal isOpen={isOpen}>
        <Loader />
        <p className="modal-wait-text">Esto puede demorar un minuto</p>
        {safeMessage && ( 
        <p>
          ¿Tienes problemas? Por favor comunícate con nosotros a través de
          {' '}          
          <a className="simple-link" href="https://wa.link/sf8rke" target="_blank" rel="noopener noreferrer">whatsapp</a>
        </p>
)}
      </Modal>
    </>
  );
}


export default Inscripcion;
