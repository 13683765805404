/* eslint-disable no-underscore-dangle */
import ReactGA from 'react-ga';
import { theBrainAPI } from '../utils/http';
import updateApproach from '../utils/updateApproach';

ReactGA.initialize(process.env.REACT_APP_GA); // Identificador Google Analytics

const updateAlumni = (alumni, alumniId) => theBrainAPI().patch(`/checkout/alumni/${alumniId}`, alumni);
const createSubscription = (body, idApproach) => theBrainAPI().post(`/payment/master/${idApproach}`, body);

function stripeService(state, body) {
  const {
    alumni, approach, ipDetails, order
  } = state;

  return new Promise(async (resolve, reject) => {
    try {
      const subscription = await createSubscription(body, approach._id);
      await updateAlumni({
        customer_id: subscription.data.customer,
      }, alumni._id);
      await updateApproach({
        subscription_id: subscription.data.subscription,
        country_detail: ipDetails.country,
        city_detail: ipDetails.city,
        meet_devf: order.meet_devf,
      },
        alumni._id, approach._id);
      resolve(subscription);
    } catch (e) {
      reject(e.response.data);
    }
  });
}  

export default stripeService;
