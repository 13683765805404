/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom'; 
import { useCheckout } from '../context';
import CashConfirm from '../components/cashConfirmation';
import CardConfirm from '../components/cardConfirmation';
import Head from '../components/head';
import Header from '../components/header';
import Footer from '../components/footer';
import GTM from '../common/GTM';
import dateFormat from '../utils/dateFormat';
import currencyFormat from '../utils/currencyFormat';

function Confirm({ history }) {
  const [state] = useCheckout();

  const {
    payment = {}, 
    alumni = {}, 
    order = {}, 
    cinta = {}, 
    batch = {}, 
    scholarship = {},
    sedeDetail = {}
  } = state || {};
  // eslint-disable-next-line no-nested-ternary
  const price = order.advance ? order.priceReserve : order.discount 
  ? order.price - order.discount 
  : order.price;
  if (!state.alumni) history.push('/?sede=remote&cinta=cintablanca');


  // sessionStorage.removeItem('__payData');
  
  useEffect(() => {
  }, [history, state]);

  return Object.keys(state).length > 0 ? (
    <>
      <GTM tags={{ pageView: 'confirm' }} />
      <Head title="Confirm" />
      <Header />
      <main id="main-inscripcion">
        <section id="confirmation" className="my-5">
          <div className="container">

            <div className="row">
              <div className="col-12 text-center">
                {
                  (payment.url) ? <CashConfirm url={payment.url} /> : <CardConfirm />

                }
                <div className="detalles mt-5">
                  <div className="row justify-content-md-center">
                    <div className="col col-md-6 py-4">
                      <p><strong>Aquí están los detalles de tu inscripción:</strong></p>
                      <p>{`${alumni.first_name} ${alumni.last_name}`}</p>
                      <p>{cinta.name}</p>
                      <p>{sedeDetail.title}</p>
                      <p>{ !scholarship.price_given ? dateFormat(batch.start_date) : '' }</p>
                      <p>
                        Precio:
                        {
                          ` ${currencyFormat(price)} ${order.currency} `
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>


      </main>
      <Footer />
    </>
  ) : <Redirect to="/?sede=remote&cinta=cintablanca" />;
}

export default Confirm;
