import axios from 'axios';
import { DJANGO_BASE_URL, THE_BRAIN_BASE_URL } from '../const';

const timeout = 90000;

const djangoAPI = () => axios.create({
    baseURL: DJANGO_BASE_URL,
    timeout
});

const theBrainAPI = () => axios.create({
  baseURL: THE_BRAIN_BASE_URL,
  auth: {
    username: process.env.REACT_APP_THE_BRAIN_USER,
    password: process.env.REACT_APP_THE_BRAIN_PASS
  }

});

export {
  djangoAPI,
  theBrainAPI
};
