/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button';
import Loader from '../common/Loader';
import Header from '../components/header';
import NewFormStripe from '../components/NewFormStripe';
import Head from '../components/head';
import Footer from '../components/footer';
import ResumeMaster from '../components/resumeMaster';
import Modal from '../common/Modal';
import Error from '../common/Error';
import GTM from '../common/GTM';
import { theBrainAPI } from '../utils/http';
import { useCheckout } from '../context';
import InfoAlumniScholarship from '../components/infoAlumniScholarship';
import PaymentFormStripe from '../components/paymentFormStripe';
import payCompleteService from '../services/payCompleteService';
import newEnrollStripeService from '../services/newEnrollStripeService';
import { PRICE_FINANCIEMIENTO } from '../const';
import 'react-floating-whatsapp-button/dist/index.css';


function PayComplete({ match, history }) {
  const [state, dispatch] = useCheckout();
  const [methodPay, setMethodPay] = useState('msi');
  const [price, setPrice] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [content, setContent] = useState('');

  const load = (isLoad, message) => {
    setIsOpen(isLoad);
    setContent(message || <Loader />);
  };

  useEffect(() => {
    async function enrollmentData() {
      try {
        const { data } = await theBrainAPI().get(`/approaches/${match.params.idApproach}`);          
        if (data.length > 0) {
          const {
            _id, first_name, last_name, email, phone, approaches 
          } = data[0];
          dispatch({ type: 'approach', payload: approaches });
          dispatch({
            type: 'alumni',
            payload: {
              first_name, last_name, email, phone, _id 
            } 
          });
          const { data: program } = await theBrainAPI().get(`/checkout/progam/${approaches.program}`);  
          dispatch({ type: 'program', payload: program });
          dispatch({ type: 'order', payload: { placement: program.name.includes('Coding') ? 12 : 6, force: true } });
          if (methodPay === 'msi') setPrice(approaches.price_given);
        } else {
          setError('Parece que no encontramos tu inscripción. Ponte en contacto con el equipo de DEV.F para resolverlo');
        }
      } catch (err) {
        setError('Tuvimos un problema procesando tu inscripción. Escríbenos a estudiantes@devf.mx para resolverlo.');
      } finally {
        setLoading(false);
      }
    }
    enrollmentData();
}, [dispatch, match.params, methodPay]);

useEffect(() => {
  if (state.approach) {
    const newPrice = methodPay === 'msi' ? state.approach.price_given : state.approach.price_given + 6000;
    setPrice(newPrice);
  }
}, [methodPay, state, state.approaches]);

  const msiSubmitStripe = async (token, month) => {
    try {
      setIsOpen(true);
      setContent(<Loader />);
      const confirmBody = {
        customer: {
            name: `${state.alumni.first_name} ${state.alumni.last_name}`,
            email: state.alumni.email,
          },
        intentId: month.paymentIntent,
        selectedPlan: month.month,
        source: {
            source: token.id,
        },
        type: 'card',
      };
      const confirm = await payCompleteService(state, confirmBody);
      if (confirm.status === 200) load(false, null);
      else {
        dispatch({ type: 'stripe', payload: { error: true } });
        setIsOpen(true);
        return setContent(<Error message="Error creando el cargo" close={setIsOpen} />);
      }
      return history.push('/paid');
    } catch (e) {
      dispatch({ type: 'stripe', payload: { error: true } });
      if (e.raw) {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email} message:${e.raw.message}` });
        return setContent(<Error message={e.raw.message} close={setIsOpen} />);
      }
      if (e.response) {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email} message:${e.response.data.raw.message}` });
        setContent(<Error message={e.response.data.raw.message} close={setIsOpen} />);
      }
      Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email}  Error nuestro` });
      return setContent(<Error message="Ha ocurrido un error desconocido. Comunícate con estudiantes@devf.mx" close={setIsOpen} />);
    }
  };

  const placementSubmitStripe = async (cardName, token) => {
    setIsOpen(true);
    setContent(<Loader />);
    try {
      const confirmBody = {
        customer: {
          name: `${state.alumni.first_name} ${state.alumni.last_name}`,
          email: state.alumni.email,
        },
        source: {
          source: token.id,
        },
        type: 'card', 
        currency: state.approach.currency,
        placement: state.order.placement,
        force: state.order.force
      };
      await newEnrollStripeService(state, confirmBody);
      history.push('/paid');
    } catch (e) {
      if (e.raw) {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email} message:${e.raw.message}` });
        setContent(<Error message={e.raw.message} close={setIsOpen} />);
      } else if (e.response) {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email} message:${e.response.data.raw.message}` });
        setContent(<Error message={e.response.data.raw.message} close={setIsOpen} />);
      } else {
        Axios.post('https://ptb.discord.com/api/webhooks/1077773257576558672/m6jbIhPNm_KU6Lq_CzkpP1K2fXeKgP9NoHeMC1bejZGm6L1ScQkktr-vBWCssYV-4G0M', { content: `User:  ${state.alumni.email}  Error nuestro` });
        setContent(<Error message="Ha ocurrido un error desconocido. Comunícate con estudiantes@devf.mx" close={setIsOpen} />);
      }
    }
  };

  const render = () => {
    if (error) return <Error message={error} close={() => {}} />;
    if (isLoading) return <Loader />;
    return (
      <div className="container py-4">
        <div className="row">
          <div className="col-md-6 order-12 order-md-1">
            <InfoAlumniScholarship
              alumni={state.alumni}
              cinta={state.program.name}
            />
            <div className="row">
              <div className="col-12 col-md-5">
                <label className={methodPay === 'msi' ? 'form-control-option form-control-option--active option-select-pay' : 'form-control-option option-select-pay'} htmlFor="months-0">
                  <input type="radio" id="months-0" name="months" value="msi" checked={methodPay === 'msi'} onClick={() => setMethodPay('msi')} />
                  Pago 
                  {' '}
                  { state.approach.product_line === 'MASTER_2D' ? 'Anual' : 'Semestral'}
                  {' '}
                </label>
              </div>
              <div className="col-12 col-md-5">
                <label className={methodPay === 'financiado' ? 'form-control-option form-control-option--active option-select-pay' : 'form-control-option option-select-pay'} htmlFor="months-0">
                  <input type="radio" id="months-0" name="months" value="financiado" checked={methodPay === 'financiado'} onClick={() => setMethodPay('financiado')} />
                  Pago mensual
                  {' '}
                </label>
              </div>  

            </div>
            {
              methodPay === 'msi' ? (
                <PaymentFormStripe
                  cardSubmit={msiSubmitStripe}
                  load={load}
                  advances={false}
                  plans
                  fullPrice
                />
              ) : (
                <NewFormStripe cardSubmit={(name, token) => placementSubmitStripe(name, token)} />
              )
            }


          </div>
          <div className="resumen col-md-6 bg-light py-5 px-4 order-1 order-md-12 mb-4">
            <ResumeMaster amount={price} fullPrice={methodPay === 'msi'} />
          </div>
        </div>
        <FloatingWhatsApp 
          phone="+525561734619"
          popupMessage="Hola, ¿Tienes alguna duda?"
          headerTitle="Ventas DEVF"
          autoOpenTimeout={60000}
          zIndex={999}
        />
        <GTM
          tags={{
                pageView: 'completedpay',
                // cinta: state.cinta.name,
                // price: state.scholarship.price_given,
                // currency: state.scholarship.currency,
              }}
        />
      
      </div>
    );
  };
  

  return (
    <div>
      <Head title="Pago completo" />
      <Header />
      {render()}
      <Footer isLoading={false} />
      <Modal isOpen={isOpen}>{content}</Modal>
    </div>
  );
}

PayComplete.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired
};

export default PayComplete;
