import React from 'react';
import { Loader as L } from 'react-loaders';


function Loader() {
  return (
    <div className="row justify-content-center">
      <div className="col-md-7 d-flex justify-content-center">
        <L type="pacman" />
      </div>
      <div className="col-md-7 text-center">
        <h3>Cargando...</h3>
      </div>
    </div>
  );
}

export default Loader;
