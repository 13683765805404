import publicIp from 'public-ip';
import axios from 'axios';

export const initSession = async () => {
  const b64State = sessionStorage.getItem('__payData') || decodeURIComponent(escape(JSON.stringify([]))); 
  const state = decodeURIComponent(escape(b64State));
  const jsonState = JSON.parse(state)[0] || { };
  if (jsonState) {
    if (jsonState.stripe) delete jsonState.stripe;
    if (jsonState.sedeDetail) {
      delete jsonState.sedeDetail.public_transport;
      delete jsonState.sedeDetail.parking;
      delete jsonState.sedeDetail.map_img;
      delete jsonState.sedeDetail.indications;
      delete jsonState.sedeDetail.cl_phone;
      delete jsonState.sedeDetail.cl_email;

      delete jsonState.sedeDetail.address;
      delete jsonState.sedeDetail.map_link;
      delete jsonState.sedeDetail.phone;
      delete jsonState.sedeDetail.logo;
      delete jsonState.sedeDetail.createdAt;
      delete jsonState.sedeDetail.updatedAt;
      delete jsonState.sedeDetail.aditional;
      delete jsonState.sedeDetail.cl_name;
    }
}
try {
  const ip = await publicIp.v4();
  const { data: ipDetails } = await axios.get(`https://ipapi.co/${ip}/json/`);
  jsonState.ipDetails = ipDetails;
} catch (error) {
  console.log(error);
}
  return jsonState;
};

export const setSession = async (state) => {
  if (state) {
    if (state.alumni && state.alumni.approaches && state.alumni.approaches.length > 0) {
      state.alumni.approaches.forEach((appraoch) => {
        if (appraoch.sede_detail) {
          delete appraoch.sede_detail.public_transport;
          delete appraoch.sede_detail.parking;
          delete appraoch.sede_detail.map_img;
          delete appraoch.sede_detail.indications;
          delete appraoch.sede_detail.cl_phone;
          delete appraoch.sede_detail.cl_email;
          delete appraoch.sede_detail.address;
          delete appraoch.sede_detail.map_link;
          delete appraoch.sede_detail.phone;
          delete appraoch.sede_detail.logo;
          delete appraoch.sede_detail.createdAt;
          delete appraoch.sede_detail.updatedAt;
          delete appraoch.sede_detail.aditional;
          delete appraoch.sede_detail.cl_name;
        }
      });
    }
    if (state.sedeDetail) {
      delete state.sedeDetail.public_transport;
      delete state.sedeDetail.parking;
      delete state.sedeDetail.map_img;
      delete state.sedeDetail.indications;
      delete state.sedeDetail.cl_phone;
      delete state.sedeDetail.cl_email;
      delete state.sedeDetail.address;
      delete state.sedeDetail.map_link;
      delete state.sedeDetail.phone;
      delete state.sedeDetail.logo;
      delete state.sedeDetail.createdAt;
      delete state.sedeDetail.updatedAt;
      delete state.sedeDetail.aditional;
      delete state.sedeDetail.cl_name;
    }
  } else {
    state = { };
  }
  try {
    const ip = await publicIp.v4();
    const { data: ipDetails } = await axios.get(`https://ipapi.co/${ip}/json/`);
    state.ipDetails = ipDetails;
  } catch (error) {
    console.log('');
  }
  const b64State = unescape(encodeURIComponent(JSON.stringify([state])));
  sessionStorage.setItem('__payData', b64State);
};
